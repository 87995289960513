@import '../../styles/reduction.scss';
@import '../../styles/fonts/font.scss';

.container {
  background-color: rgba(112, 112, 112, 0.7);
}

.paper_container {
  overflow-y: auto !important;
}

//iOS container - Fixed iOS 13 & below's issues - no pop up
.paper_container_iOS {
  overflow-y: visible !important;
}

.MuiDrawer-paperAnchorBottom {
  --drawer-width: 400px;
  --drawer-height: 400px;
  --drawer-max-width: 95vw;
  --drawer-max-height: 80vh;
  --drawer-overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  height: 37%;
  overflow: var(--drawer-overflow) !important;
  max-height: var(--drawer-max-height) !important;

  @include breakpoint(sm) {
    width: var(--drawer-width) !important;
    height: var(--drawer-height) !important;
    max-width: var(--drawer-max-width) !important;
    border-radius: 12px !important;
    margin: auto !important;
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    bottom: unset !important;
    right: unset !important;
  }
}

.drawer {
  .MuiDrawer-paperAnchorBottom {
    border-radius: 20px 20px 0px 0px;
    height: fit-content !important;
    min-height: 500px;

    @include breakpoint(sm) {
      width: 420px !important;
      height: 360px !important;
      border-radius: 12px !important;
      margin: auto !important;
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      bottom: unset !important;
      right: unset !important;
    }
  }
}

.drawer-with-title {
  .drawer {
    &-title {
      @include font_gt_walsheim_bold;
      font-size: 18px;
      padding: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      overflow: auto;
      @include scrollbar-style;
    }
  }

  .divider {
    border-bottom: 1px solid $color_gray_light_3;
    margin-bottom: 16px;
  }
}

.drawer-content {
  padding: 20px;
  height: fit-content !important;
  // font-weight: bold;

  .filter-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 8px;

    label {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      .filter-checkbox {
        margin-left: auto;
        width: 16px;
        height: 16px;
        &:checked {
          background-color: #32bfaf;
          border-color: #32bfaf;
        }
      }
    }

    .date-picker-filter {
      margin-top: 20px;
    }
  }

  .date-text {
    font-weight: bold;
    text-align: center;
    font-size: 14px;
  }

  .date-picker {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;

    label {
      text-align: center;
    }
  }
}

.drawer-share {
  .drawer-title {
    @include font_gt_walsheim_bold;
    font-size: 18px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .drawer-share-icon {
    text-align: center;
    font-size: 14px;
    cursor: pointer;

    svg {
      color: #d8284c;
      margin-bottom: 8px;
    }
  }

  .drawer-share-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 16px;

    button {
      background: transparent;
      border: none;
    }
  }

  .divider {
    border-bottom: 1px solid $color_gray_light_3;
    margin-bottom: 16px;
  }
}

.drawer-copy {
  display: flex;
  flex-direction: row;
  margin: 16px 25px;
  gap: 10px;

  .drawer-copy-button {
    display: flex;
    gap: 0.5rem;
    background-color: transparent;
    border-width: 0px;
    cursor: pointer;
  }

  .drawer-copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #d8284c;
    border-radius: 50%;
    svg {
      color: #fff;
    }
  }

  .drawer-copy-text {
    margin-top: 10px;
    font-size: 14px;
  }
}

.copy-divider {
  border-bottom: 1px solid $color_gray_light_3;
  margin: 16px 16px 0px 16px;
}

.drawer-bottom-message {
  height: 3rem;
}

.drawer-copied {
  padding: 2px 16px;
  border-radius: 32px;
  background: $amway-black;
  color: #ffffff;
  width: fit-content;
  margin: auto;
}

.drawer-burger-menu {
  background-color: white;
  z-index: 1;
  cursor: pointer;
  display: inline-block;
  width: auto;
  padding: 0;
  border: none;
  background: transparent;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  .bar {
    display: block;
    height: 2px;
    width: 20px;
    background: $color_primary;
    margin: 6px 0 6px 0;
    text-align: right;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.top,
    &.bottom {
      width: 15px;
      margin: 5px 0 0 5px;
    }

    .middle {
      margin: 0 auto;
    }
  }

  &.active {
    .bar.top,
    .bar.bottom {
      width: 20px;
    }

    .top {
      -webkit-transform: translateY(8px) rotateZ(45deg);
      -moz-transform: translateY(8px) rotateZ(45deg);
      -ms-transform: translateY(8px) rotateZ(45deg);
      -o-transform: translateY(8px) rotateZ(45deg);
      transform: translateY(8px) rotateZ(45deg);
    }

    .bottom {
      -webkit-transform: translateY(-8px) rotateZ(-45deg);
      -moz-transform: translateY(-8px) rotateZ(-45deg);
      -ms-transform: translateY(-8px) rotateZ(-45deg);
      -o-transform: translateY(-8px) rotateZ(-45deg);
      transform: translateY(-8px) rotateZ(-45deg);
    }

    .middle {
      width: 0;
    }
  }
}

.drawer-footer-button {
  padding: 16px;
  display: flex;
  gap: 16px;

  &--stack {
    flex-direction: column;
  }

  &--unstack {
    flex-direction: row-reverse;
  }

  .filter-button {
    background-color: #e11e45;
    color: white;
    border: none;
    padding: 6px;
    border-radius: 56px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }
}
