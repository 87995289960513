@import '../../styles/dynamic/all.scss';

.cr-page {
  --breadcumb-height: 42px;
  --page-header-height: 54px;
  --page-footer-height: 64px;
  --page-footer-position: absolute;
  --page-max-width: 1024px;
  --page-padding-bottom-offset: 1.5rem;
  position: relative;
  max-width: var(--page-max-width);
  margin: auto;
  display: flex;
  flex-direction: column;

  &__breadcrumbs {
    position: fixed;
    top: var(--page-header-height);
    left: 0;
    background: $color_white;
    z-index: 99;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    // @include box-shadow(0px 6px 4px -4px rgba(44, 44, 44, 0.1));

    &-wrapper {
      height: var(--breadcumb-height);
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem 1rem;
      position: relative;
      max-width: var(--page-max-width);
      margin: auto;

      button {
        border: none;
        background: transparent;
        // outline: none;
        cursor: pointer;
        padding: unset;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  &__title {
    width: 100%;

    @include breakpoint(xs) {
      width: calc(100% - 2rem);
      position: absolute;
      display: flex;
      justify-content: center;
      z-index: -1;
    }

    @include breakpoint(md) {
      position: unset;
      justify-content: unset;
    }

    h1 {
      margin: 0;
      font-size: 0.875rem;
      width: fit-content;
      max-width: 60vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__body {
    padding-top: var(--breadcumb-height);
    &--padding {
      padding-bottom: calc(var(--page-padding-bottom-offset) + var(--page-footer-height));
    }
  }

  &__footer-btn {
    position: var(--page-footer-position);
    height: var(--page-footer-height);
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__box-shadow {
      @include box-shadow(0px -6px 4px -4px rgba(44, 44, 44, 0.1));
    }
  }

  &--full-height-content {
    --page-footer-position: relative;
    .cr-page__body {
      padding-bottom: 0;
      flex: 1;
      &-wrapper {
        padding: 0 15px;
        max-height: calc(100vh - var(--page-header-height) - var(--breadcumb-height) - var(--page-footer-height));
        overflow-y: auto;
        @include scrollbar-style-overlay;
      }
    }
  }

  &--full-nav-tab-height-content {
    --page-footer-position: relative;
    .cr-page__body {
      padding-bottom: 0;
      flex: 1;
    }
    .tab-content {
      padding: 0 15px;
      max-height: calc(100vh - var(--page-header-height) - var(--breadcumb-height) - var(--page-footer-height));
      overflow-y: auto;
      @include scrollbar-style-overlay;
    }
  }
}
