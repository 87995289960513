@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

:root {
  font-size: 16px;
}

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'gt-walsheim';
  font-weight: 500;
}

.cr-app {
  --header-height: 54px;
  --footer-height: 305px;
  --content-height: calc(100vh - var(--header-height) - var(--footer-height));
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;

  &__main-content {
    margin-top: var(--header-height);
    min-height: var(--content-height);
    position: relative;

    .cr-page {
      min-height: var(--content-height);
    }
  }
}
.sr-only {
  display: none;
}

.toast {
  top: 55px;

  @media all and (min-width: 768px) {
    top: 85px;
  }
}

.embedded-text {
  line-height: 32px;
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 0.75rem;
  }
  p,
  li {
    margin-bottom: 0.6em;
  }
  strong {
    font-weight: 700;
  }
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  ol li {
    display: table;
    counter-increment: item;
  }
  ol li:before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }
  ol li li {
    margin: 0;
  }
  ol li li:before {
    content: counter(item, lower-alpha) '. ';
  }
  ol li li li:before {
    content: counter(item, lower-roman) '. ';
  }
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  ul ul {
    margin-top: 5px;
    padding-left: 20px;
  }
  ul ul ul {
    list-style-type: circle;
  }
  ul ul ul ul {
    list-style-type: square;
  }
  ul ul ul ul ul {
    list-style-type: none;
    position: relative;
  }
  ul ul ul ul ul li::before {
    content: '•';
    position: absolute;
    left: -15px;
  }
  em {
    font-style: italic;
  }
}
